<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <section class="cards">
          <h2 class="heading-card">Add New Courses</h2>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form role="form" @submit.prevent="handleSubmit(hanldeAddCourse)">
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    label="Title Course"
                    class="mb-3"
                    name="title"
                    placeholder="Write here..."
                    v-model="course.name"
                    :rules="{ required: true }"
                  ></base-input>
                </div>
                <div class="col-md-6">
                  <base-input
                    input-group-classes="input-group-merge"
                    label="Price"
                    class="mb-3"
                    name="price"
                    :rules="{ required: true }"
                    v-model="course.price"
                    type="number"
                  >
                    <template v-slot:prepend>Rp.</template>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    label="Category"
                    class="mb-3"
                    name="Category"
                    :rules="{ required: true }"
                  >
                    <select class="form-control" v-model="course.category.value">
                      <template v-if="course.category.list">
                        <option
                          v-for="Category in course.category.list"
                          :key="Category.id"
                          :value="Category.id"
                        >{{ Category.name }}</option>
                      </template>
                    </select>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <!-- <base-input
                    label="Trainer"
                    class="mb-3"
                    name="Trainer"
                    :rules="{ required: true }"
                  >
                    <select class="form-control" v-model="course.trainer.value">
                      <template v-if="course.trainer.list">
                        <option
                          v-for="Trainer in course.trainer.list"
                          :key="Trainer.id"
                          :value="Trainer.id"
                        >{{ Trainer.name }}</option>
                      </template>
                    </select>
                  </base-input>-->
                  <base-input
                    label="Minimum Point"
                    class="mb-3"
                    name="point"
                    placeholder="Write here..."
                    type="number"
                    :rules="{ required: true, }"
                    v-model="course.point"
                  ></base-input>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <base-input
                    label="Minimum Point"
                    class="mb-3"
                    name="point"
                    placeholder="Write here..."
                    type="number"
                    :rules="{ required: true, }"
                    v-model="course.point"
                  ></base-input>
                </div>
              </div>-->
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="video" class="form-control-label">Introduction Video</label>
                    <div class="has-label">
                      <div class="wrapper-input-video">
                        <div :class="[course.video.value ? 'video-preview' : 'thumbnail-video']">
                          <div v-if="!course.video.value" class="back-video">
                            <img src="/img/video.png" alt="video-logo" />
                          </div>
                          <video width="100%" v-else :src="course.video.preview" controls></video>
                        </div>
                        <label class="label-upload">
                          <div
                            class="btn base-button btn-outline-primary btn-sm button-upload"
                          >Upload Video</div>
                          <input
                            @change="previewVideo"
                            class="file-upload"
                            type="file"
                            accept="video/*"
                            ref="videoFile"
                          />
                        </label>
                        <!-- </base-button> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="video" class="form-control-label">Thumbnail Photo</label>
                    <div class="has-label">
                      <div class="wrapper-input-video">
                        <div class="thumbnail-video">
                          <div v-if="!course.image.value" class="back-video">
                            <img src="/img/thumbnail-photo.png" alt="thumbnail-photo-logo" />
                          </div>
                          <img
                            class="photo"
                            v-else
                            :src="course.image.preview"
                            alt="image upload by user"
                          />
                        </div>
                        <!-- <base-button size="sm" outline type="primary" class="button-upload">
                          <label class="label-upload">
                            <div>Upload Photo</div>
                            <input
                              class="file-upload"
                              @change="previewImage"
                              type="file"
                              accept="image/*"
                              ref="imageFile"
                            />
                          </label>
                        </base-button>-->
                        <label class="label-upload">
                          <div
                            class="btn base-button btn-outline-primary btn-sm button-upload"
                          >Upload Photo</div>
                          <input
                            hidden="true"
                            type="file"
                            accept="image/*"
                            ref="imageFile"
                            @change="previewImage"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="form-control-label">Description</label>
                  <vue-editor
                    placeholder="Write description in here ..."
                    v-model="course.description"
                  />
                </div>
              </div>
              <div class="text-right">
                <base-button outline type="primary" class="my-4" @click="handleBack">Back</base-button>
                <base-button type="success" class="my-4" native-type="submit">Add New Course</base-button>
              </div>
            </form>
          </validation-observer>
        </section>
      </div>
    </div>
    <modal :show.sync="showModalAddCourse" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Add New Course</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="add-course">add a new course</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="showModalAddCourse = false">Back</base-button>
          <base-button type="success" class="my-4" @click="onSubmit">Add New Course</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="loading" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
  </div>
</template>
<script>
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import { VueEditor } from "vue2-editor";
import { getAllTrainer } from '@/services/trainer'
import { getAllCategory } from '@/services/category'
import { addCourse } from '@/services/course'
import LoadingPanel from '@/components/LoadingPanel.vue';
// import ImageResize from "quill-image-resize-module";
// Quill.register("modules/imageResize", ImageResize);


export default {
  name: "AddCourses",
  components: {
    DashboardNavbar,
    VueEditor,
    LoadingPanel
  },
  data() {
    return {
      routes: [
        {
          name: "listCourses",
          alias: "List Courses"
        },
        {
          name: "listCourses",
          alias: "ADD NEW COURSES"
        },
      ],
      course: {
        name: "",
        price: 0,
        category: {
          value: '',
          list: []
        },
        trainer: {
          // value: '',
          value: 1,
          list: []
        },
        point: 0,
        video: {
          value: null,
          preview: null,
        },
        image: {
          value: null,
          preview: null,
        },
        description: ""
      },
      showModalAddCourse: false,
      loading: false
    }
  },
  methods: {
    hanldeAddCourse() {
      if (this.course.image.value && this.course.video.value && this.course.description !== "") {
        this.showModalAddCourse = true
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Video, Photo and Description must be filled"
        });
      }
    },
    async onSubmit() {
      try {
        const form = new FormData();
        form.append('name', this.course.name);
        form.append('description', this.course.description);
        form.append('categoryId', this.course.category.value);
        form.append('userId', this.course.trainer.value);
        form.append('price', this.course.price);
        form.append('min_point', this.course.point);
        form.append('photo', this.course.image.value);
        form.append('video', this.course.video.value);
        this.showModalAddCourse = false
        this.loading = true
        await addCourse(form)
        this.loading = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Course successfully Added"
        });
        setTimeout(() => {
          this.$router.push({ name: "listCourses" })
        }, 100);
      } catch (error) {
        this.loading = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    handleBack() {
      this.$router.push({ name: "listCourses" });
    },
    previewImage() {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (types.includes(this.$refs.imageFile.files[0].type)) {
        if ((this.$refs.imageFile.files[0].size / 1024).toFixed(2) < 3000) {
          this.course.image.value = this.$refs.imageFile.files[0];
          this.course.image.preview = URL.createObjectURL(this.course.image.value);
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "File size exceeds the limit"
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be png/jpg/jpeg"
        });
      }
    },
    previewVideo() {
      this.course.video.value = this.$refs.videoFile.files[0];
      this.course.video.preview = URL.createObjectURL(this.course.video.value);
    },
    async getTrainer() {
      try {
        const { data } = await getAllTrainer()
        this.course.trainer.list = data
        this.course.trainer.value = data[0].id
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async getCategory() {
      try {
        const { data } = await getAllCategory()
        this.course.category.list = data
        this.course.category.value = data[0].id
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
  },
  mounted() {
    // this.getTrainer()
    this.getCategory()
  }
}
</script>
 <style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.cards {
  background-color: #fff;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  .heading-card {
    font-size: 24px;
    color: #000;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.wrapper-input-video {
  display: flex;
  align-items: flex-end;
  padding: 0 10px;

  .video-preview {
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;
    width: 220px;
    max-height: 150px;
  }

  .thumbnail-video {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;

    .back-video {
      width: 100%;
      height: 100%;
      background: $tabs-course;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .button-upload {
    margin-left: 10px;
  }
}

.label-upload {
  margin: 0;
  cursor: pointer;
  .file-upload {
    display: none;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .add-course {
    color: $status-pass-ar-vr;
  }
}
</style>
